module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Koda Camp","short_name":"Koda","start_url":"/","background_color":"#EF301F","theme_color":"#EF301F","display":"standalone","icon":"src/images/meta/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"500d0f7c4cd8874c899621aefffbf6ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"koda.camp"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
